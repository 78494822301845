<template>
  <div>
    <DynamicContent
      @loaded="onContentLoaded"
      :key="path"
      :path="path"
      v-if="isInDynamicContentsList"
    />
    <NotFound v-else />
  </div>
</template>

<script>
import DynamicContent from "../components/DynamicContent";
import NotFound from "@/blocks/not-found/views/NotFound";
export default {
  components: {
    NotFound,
    DynamicContent,
  },
  head() {
    if (this.page) {
      return this.page.head;
    }
  },
  data() {
    return {
      page: null,
    };
  },
  computed: {
    path() {
      return this.$route.path;
    },
    isInDynamicContentsList() {
      for (let [key, value] of Object.entries(this.$store.state.page.routes)) {
        if (this.path === key) return true;
      }

      return false;
    },
  },
  methods: {
    onContentLoaded(page) {
      this.page = page;
    },
  },
};
</script>
