<template>
  <v-container class="h-100">
    <v-row v-if="loaded" no-gutters class="d-flex justify-center my-10">
      <div v-if="isLoggedIn" class="text-center">
        <v-alert tile type="success">Olete sisse logitud!</v-alert>
        <LogoutBtn tile solo color="black" dark @change="testIfLoggedIn()" />
      </div>
      <v-col v-else cols="12" sm="9" md="6">
        <AuthForm elevation="0" @authenticated="onAuthenticated" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthForm from "../components/AuthForm.vue";
import LogoutBtn from "../components/LogoutBtn.vue";

export default {
  components: {
    AuthForm,
    LogoutBtn,
  },
  head() {
    return { title: "Sisenemine" + this.$store.getters.setting("meta_brand") };
  },
  data() {
    return {
      isLoggedIn: null,
      loaded: false,
    };
  },
  created() {
    this.testIfLoggedIn();
  },
  methods: {
    onAuthenticated({ intended, user }) {
      if (intended) {
        window.location.replace(intended);
      } else {
        this.$router.go(-1);
      }
    },
    testIfLoggedIn() {
      this.axios({
        method: "GET",
        baseURL: "/",
        url: "api/auth/check",
      })
        .then((response) => {
          this.isLoggedIn = response.data.status;
          setTimeout(() => {
            this.loaded = true;
          }, 300);
        })
        .catch((error) => {})
        .then(() => {});
    },
  },
};
</script>
