<template>
  <Typography />
</template>

<script>
import Typography from "@/blocks/_dev/views/Typography";
export default {
  components: {
    Typography,
  },
};
</script>

<style>
</style>
