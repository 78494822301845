
<template>
  <v-container class="h-100">
    <v-row no-gutters class="d-flex justify-center my-10">
      <v-col cols="12" sm="9" md="6">
        <div>
          <v-card tile class="px-5 pb-3 pt-5">
            <h1 class="text-h3 text-center mb-4">Parooli värskendamine</h1>

            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" sm="9">
                  <form>
                    <v-text-field
                      outlined
                      filled
                      persistent-hint
                      class="mb-5"
                      name="email"
                      v-model="form.email"
                      label="E-mail"
                      :error-messages="form.error('email')"
                      :error="form.state('email')"
                      hint="Sisestage e-mail, et saaksime edastada juhised parooli taastamiseks."
                      required
                    />

                    <v-alert tile v-if="errorMessage" type="error" class="mt-3">
                      <span v-html="errorMessage" />
                    </v-alert>

                    <v-alert tile v-if="successMessage" type="success" class="mt-3">
                      <span v-html="successMessage" />
                    </v-alert>

                    <div class="d-flex justify-end">
                      <base-btn
                        :readonly="successMessage !== null"
                        name="submit"
                        color="black"
                        dark
                        class="mr-4"
                        :loading="loading"
                        @click="submit"
                      >
                        <span>Saada</span>
                      </base-btn>
                    </div>
                  </form>
                  <div class="mt-7">
                    <router-link :to="{ name: 'Login' }" class="link text--secondary">
                      Parool meenus? Logi sisse.
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Form from "@/common/form";
export default {
  head() {
    return { title: "Parooli värskendamine" + this.$store.getters.setting("meta_brand") };
  },
  data() {
    return {
      showTermsDialog: false,
      form: new Form({
        email: "",
      }),
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
      errorMessage: null,
      successMessage: null,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.clearMessages();
      const route = "api/auth/reset";
      this.form
        .post(route, "/")
        .then((response) => {
          this.$emit("change");
          this.successMessage = response.data.message;
          this.loading = false;
        })
        .catch((error) => {
          this.errorMessage = this._.get(error.response.data, "message", error.message);
          this.loading = false;
        });
    },
    clearMessages() {
      this.errorMessage = null;
      this.successMessage = null;
    },
  },
};
</script>
